<template>
  <base-section id="theme-features">
    <base-section-heading title="Pagos">
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
                <add-payment :deviceId="parseInt(currentDeviceId)" @update="reload" @close="closeDialog" :editItem="editingItem" />
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </base-section-heading>
    
    <v-container>
      <v-row>
        <v-col>
            
            <v-data-table
            :headers="headers"
            :items="pays"
            :loading="loading"
            class="elevation-1"
            >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
              >
                  mdi-pencil
              </v-icon>
              <v-icon
                  small
                  @click="deleteItem(item)"
              >
                  mdi-delete
              </v-icon>
              </template>
              <template v-slot:no-data>
              <v-btn
                  color="primary"
                  @click="initialize"
              >
                  Reset
              </v-btn>
            </template> 
                
            </v-data-table>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item ({{deletingItem.id}})?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  metaInfo: { title: 'Payments' },
    components:{
      addPayment: () => import('./sections/payments/addPayment')
    },
    data: () => ({
        totalPays: 0,
        pays: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'Id',
            align: 'start',
            value: 'id',
          },
          { text: 'Cantidad', value: 'quantity' },
          { text: 'Period', value: 'period' },
          { text: 'Type', value: 'pack' },
          { text: 'Date', value: 'date' },
          { text: 'Pago', value: 'paymenttypeid' },
          { text: 'Device', value: 'device.name' },
          { text: 'Transaction Id', value: 'transaction' },
          { text: 'Invoice XML', value: 'invoicexml' },
          { text: 'Actions',value:'actions'}
        ],
        currentDeviceId:0,
        dialogDelete:false,
        deletingItem:{},
        dialog:false,
        editingItem:{},
    }),
    mounted(){
        var id = this.$route.params?.id;
        this.currentDeviceId = id;
        this.loadPayments(id);
    },
    methods:{
        ...mapActions(['getDevicePayments','deletePayment','showSnackbar']),
        loadPayments(id){
          this.loading = true;
            this.getDevicePayments(id).then((res) => {
                this.pays = res.data.data;

                console.log(this.pays);
                this.loading = false;
            })
            .catch((err)=>{
                console.log(err);
            });
        }
        ,editItem(item){
          //console.log(item);
          this.editingItem = Object.assign({},item);
          this.dialog = true;
        }
        ,deleteItem(item){
          this.deletingItem = item;
          this.dialogDelete = true;
        }
        ,deleteItemConfirm(){
          this.deletePayment(this.deletingItem.id).then((res) => {
                //this.pays = res.data.data;
                console.log(res);
                if(res.status == 200){
                        this.showSnackbar({
                            showing:true,
                            message: res.data.errors[0],
                            color:'success'
                        });

                        //this.$emit('update');
                    }
                //console.log(res);
                //this.loading = false;
                this.dialogDelete = false;
                this.reload();
            })
            .catch((err)=>{
                console.log(err);
            });
          //console.log(this.deletingItem);
        }
        ,reload(){
          this.dialog=false;
          this.$nextTick(() => {
              this.editingItem = Object.assign({},{
                    quantity:0,
                    period:0,
                    date:null,
                    paymenttypeid:0,
                    pack:0,
                    deviceId:0});
            //this.editedIndex = -1
              //this.loadPayments(this.currentDeviceId);
            });
          this.loadPayments(this.currentDeviceId);
        }
        ,closeDialog(){
          this.dialog = false;
        }
    }
}
</script>

<style>

</style>